<template>
  <div class="notif--item" @click="clickHandler">
    <div class="notif--image-wrapper">
      <img class="notif--image" :src="inbox.userPartnerData.photoProfile" />
    </div>
    <div class="inbox--text-wrapper">
      <div class="d-flex justify-space-between align-center">
        <div class="inbox--name">{{ inbox.userPartnerData.fullName }}</div>
        <div class="notif--ago">
          {{ formattedCreatedAt }}
        </div>
      </div>
      <div class="d-flex justify-space-between align-center">
        <div class="inbox--message multiline-ellipsis-3">
          {{ stripHtml(inbox.lastMessage) }}
        </div>
        <div v-show="inbox.unread_count > 0" class="inbox--unread">{{ inbox.unread_count }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
export default {
  name: 'inbox-item',
  mixins: [HelperMixin],
  props: ['inbox'],
  computed: {
    formattedCreatedAt() {
      return this.$date.format(this.inbox.lastMessageTimeStamp, 'dd MMM HH:mm');
    },
  },
  methods: {
    clickHandler() {
      this.$router.push({
        path: `/inbox/${this.inbox.msgThreadUuid}`,
      });
    },
  },
};
</script>
